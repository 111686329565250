import { css, Global } from "@emotion/core"
import styled from "@emotion/styled"
import emotionReset from "emotion-reset"
import { graphql, StaticQuery } from "gatsby"
import React from "react"
import Helmet from "react-helmet"
import { Footer } from "../components/Footer"
import { Header } from "../components/Header"
import { ResumeSection } from "../components/ResumeSection"

const IndexPage = () => (
  <StaticQuery
    query={graphql`
      query {
        bodyBackground: file(relativePath: { eq: "bg.png" }) {
          publicURL
        }
        wrapperBackground: file(relativePath: { eq: "sos.png" }) {
          publicURL
        }
      }
    `}
    render={data => (
      <>
        <Helmet>
          <link
            href="https://fonts.googleapis.com/css?family=Dawning+of+a+New+Day"
            rel="stylesheet"
          />
          <link
            href="https://fonts.googleapis.com/css?family=Lato:300,400"
            rel="stylesheet"
          />
        </Helmet>
        <Global
          styles={css`
            ${emotionReset}
            html {
              background-color: rgb(20, 20, 20);
              color: rgb(52, 73, 94);
            }

            body {
              position: absolute;
              background-image: url(${data.bodyBackground.publicURL});
              font-family: "Lato", "Helvetica Neue", Helvetica, Arial,
                sans-serif;
              font-weight: 300;
              width: 100%;
            }

            strong {
              font-weight: 400;
            }

            p {
              margin-bottom: 8px;
            }

            ul {
              list-style: square outside;
              padding-top: 4px;
              margin-left: 8px;

              li {
                margin: 0 0 0.3em 0.6em;
              }
            }
          `}
        />
        <Wrapper backgroundURL={data.wrapperBackground.publicURL}>
          <Header />
          <section>
            <ResumeSection title="Profile">
              <p>
                Experienced language agnostic Software Engineer with a
                demonstrated history of working in the industry. Skilled in all
                aspects of software engineering, requirements specification, and
                team management. Strong engineering professional with a Bachelor
                of Software Engineering &amp; Physics from University of New
                South Wales.
              </p>
            </ResumeSection>
            <ResumeSection title="Experience">
              <p>
                <strong>Principal Software Engineer, Dovetail</strong> — 2018 -
                Present
              </p>
              <p>Research platform for teams.</p>
              <p>
                <strong>Senior Software Engineer, Style Atlas</strong> — 2018
              </p>
              <p>
                Fashion lifecycle process management and fitting solution.
                <ul style={{ marginLeft: 24 }}>
                  <li>Full stack engineering role including team management</li>
                  <li>System architecture and team resource allocation</li>
                </ul>
              </p>
              <p>
                <strong>Chief Software Engineer, Premonition.io</strong> — 2017
                - 2018
                <br />
                <strong>Software Engineer, Premonition.io</strong> — 2015 - 2016
              </p>
              <p>
                Logistics Optimisation &amp; Route Planning Solution.
                <ul style={{ marginLeft: 24 }}>
                  <li>
                    Senior engineering role including management
                    responsibilities
                  </li>
                  <li>
                    Full stack role including mobile development and external
                    integrations
                  </li>
                  <li>Project and engineering team management</li>
                  <li>Solution Design and Planning</li>
                </ul>
              </p>
              <p>
                <strong>Software Developer, Ureferjobs</strong> — 2013 - 2015
              </p>
              <p>
                Recruitment referral product.
                <ul style={{ marginLeft: 24 }}>
                  <li>Backend and frontend development on product</li>
                  <li>Research and benchmarking of competitor products</li>
                </ul>
              </p>
              <p>
                <strong>
                  Lead Software Developer &amp; Project Manager, Loafly
                </strong>{" "}
                — 2014
              </p>
              <p>
                <strong>Tutor, University of New South Wales</strong> — 2013
              </p>
              <p>
                <strong>Developer, Chris Manouvrier's Concepts</strong> — 2007 -
                Preset
              </p>
              <p>
                <strong>Assistant Teacher, The Spot Preschool</strong> — 2007 -
                2013
              </p>
            </ResumeSection>
            <ResumeSection title="Education">
              <p>
                <strong>Udacity</strong> - Flying Car Nanodegree, 2018
              </p>
              <p>
                <strong>University of New South Wales</strong> - Bachelor of
                Software Engineering &amp; Science (Physics), 2011 - 2015
                <ul style={{ marginLeft: 24 }}>
                  <li>
                    Thesis: Intelligent indoor localisation and navigation with
                    smart glasses
                  </li>
                </ul>
              </p>
            </ResumeSection>
            <ResumeSection title="Skills">
              <ul>
                <li>
                  Software Engineering; Node.js, Typescript, React, EmberJS,
                  Ionic, Scala, Java, C++, Ruby, Haskell, Serverless
                </li>
                <li>Infrastructure; Kubernetes, Docker, AWS, Azure Cloud</li>
                <li>Solution Architecture</li>
                <li>Project Management</li>
                <li>Team Management</li>
              </ul>
            </ResumeSection>
            <ResumeSection title="Awards">
              <ul>
                <li>
                  iAwards National - Tertiary Undergraduate Student Project
                  Winners - 2014
                </li>
                <li>Microsoft Imagine Cup - World Citizen Nationals - 2014</li>
                <li>QuantumLinx COMP2111 Prize - 2012</li>
                <li>Macquarie 2nd Year Software Engineering Prize - 2012</li>
                <li>UNSW Engineering Dean's Honours List - 2011, 2012, 2013</li>
              </ul>
            </ResumeSection>
            <ResumeSection title="References">
              <p>Available upon request</p>
            </ResumeSection>
          </section>
        </Wrapper>
        <Footer />
      </>
    )}
  />
)

const Wrapper = styled.div<{ backgroundURL: string }>`
  margin: 20px auto;
  max-width: 1080px;
  border-radius: 4px;
  background-color: #ecf0f1;
  background-image: ${props => `url(${props.backgroundURL})`};
  padding: 20px 20px 40px 20px;

  @media (max-width: 1120px) {
    margin: 20px;
  }
`

export default IndexPage
