import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import css from "@emotion/css"
import styled from "@emotion/styled"

export const ProfileImage = () => (
  <StaticQuery
    query={graphql`
      query {
        profileImage: file(relativePath: { eq: "me.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <ImageWrapper>
        <Img
          style={{ borderRadius: "50%" }}
          fluid={data.profileImage.childImageSharp.fluid}
        />
      </ImageWrapper>
    )}
  />
)

const ImageWrapper = styled.div`
  width: 120px;
  height: 120px;
  margin: 16px auto;
`
