import styled from "@emotion/styled"
import React from "react"

export const ResumeSection: React.FC<{ title: string }> = ({
  title,
  children,
}) => (
  <Wrapper>
    <Title>{title}</Title>
    <Content>{children}</Content>
  </Wrapper>
)

const Wrapper = styled.article`
  border-top: 1px solid #1abc9c;
  font-size: 0.8em;
  display: flex;
  margin: 8px 0 0 0;
`

const Title = styled.h1`
  border-top: 4px solid #34495e;
  font-size: 1.8em;
  font-weight: 300;
  flex: 3;
  margin: 0 16px;
  padding-top: 8px;
`

const Content = styled.section`
  flex: 7;
  margin: 0 16px;
  padding-top: 8px;
  font-size: 14px;
  line-height: 18px;
`
