import styled from "@emotion/styled"
import { graphql, StaticQuery } from "gatsby"
import React from "react"
import { GitHub, Linkedin } from "react-feather"
import Helmet from "react-helmet"
import { ProfileImage } from "../ProfileImage"

export const Header = () => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
            linkedIn
            github
          }
        }
      }
    `}
    render={data => (
      <HeaderWrapper>
        <Helmet title={data.site.siteMetadata.title} />
        <ProfileImage />
        <Name>Christopher Manouvrier</Name>
        <JobTitle>Software Engineer</JobTitle>
        <Location>Sydney, Australia</Location>
        <div>
          <ProfileLink href={data.site.siteMetadata.linkedIn}>
            <Linkedin size={16} />
          </ProfileLink>
          <ProfileLink href={data.site.siteMetadata.github}>
            <GitHub size={16} />
          </ProfileLink>
        </div>
      </HeaderWrapper>
    )}
  />
)

const HeaderWrapper = styled.header`
  text-align: center;
  margin-bottom: 16px;
`

const Name = styled.h1`
  font-family: "Dawning of a New Day", cursive;
  font-size: 3.6em;
  font-weight: 300;
  margin-bottom: 8px;
`

const JobTitle = styled.h2`
  font-size: 2.2em;
  font-weight: 300;
`

const Location = styled.h3`
  font-size: 1.8em;
  font-weight: 300;
  margin-bottom: 8px;
`
const ProfileLink = styled.a`
  color: #34495e;
  padding: 4px;

  &:hover {
    color: #1abc9c;
  }
`
