import styled from "@emotion/styled"
import { graphql, StaticQuery } from "gatsby"
import React from "react"

export const Footer = () => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            linkedIn
          }
        }
      }
    `}
    render={data => (
      <StyledFooter>
        If you would like to contact me, you can find me on{" "}
        <ProfileLink href={data.site.siteMetadata.linkedIn}>
          LinkedIn
        </ProfileLink>
      </StyledFooter>
    )}
  />
)

const StyledFooter = styled.footer`
  color: #1abc9c;
  text-align: center;
  margin: 8px auto 16px;
  font-weight: 300;
`

const ProfileLink = styled.a`
  padding: 4px;
  color: #34495e;
  text-decoration: none;

  &:hover {
    color: #1abc9c;
    text-decoration: underline;
  }
`
